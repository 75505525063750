/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"

const page404 = () => (

	<Layout>
    <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>  
        <h1>Page not found</h1>
        <p>Oops! The page you are looking for has been removed or relocated.</p>
        <br/>
        <a href="/"> Go Back </a>
    </div>  
  </Layout>

	)
export default page404